import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';

export default function useVerifyOTP(props, emit, refFormObserver) {
  const toastification = toast();

  const refModalVerifyPhone = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const requestMissCallOTP = () => {
    store.dispatch('contract/requestSigningOTP', { phone: props.item.phone, secretCode: props.item.secretCode, contractNumber: props.item.contractNumber })
      .then(() => {
        toastification.showToastSuccess('Vui lòng kiểm tra điện thoại, sẽ có số điện thoại gọi đến. Hãy lấy 4 số cuối của số điện thoại gọi đến làm mã OTP');
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const open = () => {
    resetModal();
    requestMissCallOTP();
  };

  const onSubmit = async bvModalEvt => {
    try {
      bvModalEvt.preventDefault();

      const success = await refForm.value.validate();
      if (!success) {
        return;
      }

      const data = {
        pin: itemLocal.value.pin,
        phone: props.item.phone,
        contractNumber: props.item.contractNumber,
        secretCode: props.item.secretCode,
        signature: props.item.signature,
      };

      store
        .dispatch('contract/signContract', data)
        .then(() => { emit('on-item-updated'); })
        .then(() => {
          refModalVerifyPhone.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastSuccess('Chúc mừng bạn đã ký hợp đồng điện tử thành công!');
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    } catch (error) {

    }
  };

  return {
    refModalVerifyPhone,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
    open,
    requestMissCallOTP,
  };
}
