<template>
  <div>

    <b-modal
      id="modal-signature-pad"
      ref="refModal"
      :title="t('Thêm chữ ký')"
      size="md"
      :ok-title="t('Xác nhận')"
      :cancel-title="t('Huỷ')"
      cancel-variant="outline-secondary"
      :hide-header-close="true"
      no-close-on-esc
      scrollable
      no-close-on-backdrop
      @ok="onSaveSignature"
    >
      <!-- Body -->
      <vue-signature
        ref="signature"
        class="border border-primary"
        :sig-option="option"
        :w="'400px'"
        :h="'400px'"
        :disabled="disabled"
      /></b-modal>

  </div>

</template>

<script>
import {
  BModal,
  VBModal,

} from "bootstrap-vue";
import VueSignature from "vue-signature";
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,

    VueSignature,

  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      disabled: false,
      dataUrl: "https://avatars2.githubusercontent.com/u/17644818?s=460&v=4",
    };
  },
  methods: {
    onSaveSignature() {
      const png = this.$refs.signature.save();
      this.$emit('on-save-signature', png);
    },
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },

};
</script>
