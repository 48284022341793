<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="contract-list-container">
    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner
        class="text-center"
        variant="secondary"
      />
    </div>
    <div
      v-else
      class="sign-contract-content"
    >

      <b-tabs
        style="width: 100%;"
        class=""
        justified
      >
        <b-tab
          v-if="contractTerm"
          active
        >
          <template #title>
            <feather-icon icon="FileTextIcon" />
            <span>{{ t('Điều khoản hợp đồng') }}</span>
          </template>

          <div style="-webkit-overflow-scrolling: touch; overflow-y: scroll;">
            <!-- <iframe
              v-if="contractTerm && !isLoading"
              class="pdf-preview"
              :src="`${contractTerm}?zoom=100`"
              style="width: 100%; border: none; position: absolute"
            /> -->

            <!-- <vue-pdf-embed :source="contractTerm" /> -->
            <vue-office-pdf
              :src="contractTerm"
            />
          </div>

        </b-tab>
        <b-tab v-if="handover">
          <template #title>
            <feather-icon icon="PaperclipIcon" />
            <span>{{ t('Biên bản bàn giao') }}</span>
          </template>
          <div style="-webkit-overflow-scrolling: touch; overflow-y: scroll;">
            <iframe
              v-if="handover && !isLoading"
              class="pdf-preview"

              :src="`${handover}?zoom=100`"
              style="width: 100%; border: none; position: absolute"
            />
          </div>

        </b-tab>

      </b-tabs>
      <div class="sign-contract-action bg-primary text-center">
        <!-- <b-button class="my-1">
          Ký ngay
        </b-button> -->
        <div
          v-if="contract.signStatus.id !== '3'"
          class="my-1 text-center"
        >
          <b-button
            v-b-modal.modal-signature-pad
            variant="warning"
          >
            {{ signature ? 'Ký lại' : 'Thêm chữ ký' }}
          </b-button>
          <b-button
            v-if="signature"
            v-b-modal.modal-verify-otp
            class="ml-2"
            variant="secondary"
          >
            {{ t('Xác nhận & ký hợp đồng') }}
          </b-button>
        </div>
      </div>
    </div>

    <signature-pad @on-save-signature="onSaveSignature" />
    <verify-otp
      v-if="contract"
      :item="{...item, signature: signature}"
      @refetch-data="fetchData"
    />
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  VBTooltip,
  BButton,
  VBModal,
  BTabs,
  BTab,
  BSpinner,
} from 'bootstrap-vue';

import { useUtils as useI18nUtils } from '@core/libs/i18n';
import VueOfficePdf from '@vue-office/pdf';
import VerifyOtp from './VerifyOTP.vue';
import useSignContractV2 from './useSignContractV2';
import SignaturePad from './SignaturePad.vue';

export default {
  components: {
    BButton,
    SignaturePad,
    VerifyOtp,
    BTabs,
    BTab,
    BSpinner,
    VueOfficePdf,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  setup() {
    const {
      item,
      contract,
      signature,
      contractTerm,
      handover,
      isLoading,
      fetchData,
      onSaveSignature,
      onDownloadContractPDF,
    } = useSignContractV2();

    const { t } = useI18nUtils();

    return {
      contract,
      item,
      signature,
      contractTerm,
      handover,
      isLoading,
      fetchData,
      onSaveSignature,
      onDownloadContractPDF,
      t,
    };
  },

  created() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.contract-list-container {
  // height: 100vh;
  position: absolute;
}
.sign-contract-action {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  height: 60px;
}
.sign-contract-content {
  width: 100vw;
  margin-bottom: 60px;
}
.pdf-preview {
  height: calc(100vh - 100px);
}
</style>
