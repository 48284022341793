import { ref } from '@vue/composition-api';
import store from '@/store';
import { useRouter } from '@core/utils/utils';
import toast from '@/utils/toast';

export default function useSignContract() {
  // Use toast
  const toastification = toast();

  const { route } = useRouter();
  const { query } = route.value;

  const item = ref(JSON.parse(JSON.stringify(query)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(query));
  };
  const contract = ref(null);
  const contractTerm = ref(null);
  const handover = ref(null);
  const signature = ref(null);
  const isLoading = ref(false);

  const fetchData = () => {
    isLoading.value = true;
    store.dispatch('contract/getContractSigningDocuments', item.value).then(response => {
      contract.value = response.data.contract;
      contractTerm.value = response.data.contractTerm;
      handover.value = response.data.handover;
      isLoading.value = false;
    }).catch(error => {
      toastification.showToastError(error);
      isLoading.value = false;
    });
  };

  const onSaveSignature = base64Image => {
    signature.value = base64Image;
  };

  const onDownloadContractPDF = () => {
    if (contract.value.signedPdf) {
      window.location.href = contract.value.signedPdf;
    }
  };

  return {
    item,
    contract,
    signature,
    contractTerm,
    handover,
    isLoading,
    resetItem,
    fetchData,
    onSaveSignature,
    onDownloadContractPDF,
  };
}
